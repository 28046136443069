import {loaderIncrease, loaderDecrease, loaderReset} from "./services/loader/actions";
import store from "./services/store"
import CONFIG from "./config";

//const USE_CDN = false;

//const NEOS_API = CLOUDX_NEOS_API;
//const NEOS_BLOB = NEOS_CLOUD_BLOB;
//const NEOS_ASSETS = NEOS_BLOB + "assets/";
//const NEOS_THUMBNAILS = NEOS_BLOB + "thumbnails/";
//const NEOS_INSTALL = NEOS_BLOB + "install/";

const CLOUDX_NEOS_API = "https://cloudx.azurewebsites.net";
//const CLOUDX_NEOS_BLOB = "https://cloudxstorage.blob.core.windows.net/";
//const CLOUDX_NEOS_CDN = "https://cloudx.azureedge.net/"; // CDN

//const NEOS_CLOUD_BLOB = USE_CDN ? CLOUDX_NEOS_CDN : CLOUDX_NEOS_BLOB;
let loadingTimeout = {};

const API = {
    jwt: "",

    fetch: function (url, configuration) {
        store.dispatch(loaderIncrease());
        loadingTimeout[url] = setTimeout(function (){
            store.dispatch(loaderReset())
        }, 30*1000);

        try {
            const fetch_promise = fetch(url, configuration);
            fetch_promise.then(function (){
                store.dispatch(loaderDecrease());

                if (url in loadingTimeout) {
                    clearTimeout(loadingTimeout[url]);
                }
            });
            fetch_promise.catch(function (){
                store.dispatch(loaderDecrease());

                if (url in loadingTimeout) {
                    clearTimeout(loadingTimeout[url]);
                }
            });
            return fetch_promise;
        } catch (e) {
            return "{'Message':'Problem with API connection.'}";
        }
    },

    constructJsonData: function (data) {
        return JSON.stringify(data);
    },

    parseResponse: function (response) {
        if (!response.ok) {
            let jsonContent;

            if (!response) {
                throw response.status;
            }

            return response.text().then((textContent) => {
                console.log(textContent);

                try {
                    jsonContent = JSON.parse(textContent);
                } catch (e) {
                    console.log(textContent);
                    throw new Error(textContent);
                }

                let data = jsonContent;

                if (typeof data === "string") {
                    throw data;
                }
                if ("Message" in data) {
                    throw data.Message;
                }
                if ("message" in data) {
                    throw data.message;
                }
                if ("error" in data) {
                    if (data.error == "2FA RUN") {
                        return data.error;
                    }
                    throw data.error;
                }

                throw new Error("Problem with API connection.");
            });
        }

        return response.text().then((textContent) => {
            try {
                return JSON.parse(textContent);
            } catch (e) {
                return textContent;
            }
        });
    },

    registration: (user) => API.fetch(CLOUDX_NEOS_API + "/api/users", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=UTF-8'
        },
        cache: 'no-cache',
        body: JSON.stringify(user)
    }).then(response => API.parseResponse(response)),

    lostPassword: (data) => API.fetch(CLOUDX_NEOS_API + "/api/users/requestlostpassword", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=UTF-8'
        },
        cache: 'no-cache',
        body: JSON.stringify(data)
    }).then(response => API.parseResponse(response)),

    resetPassword: (data) => API.fetch(CLOUDX_NEOS_API + "/api/userSessions", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=UTF-8'
        },
        cache: 'no-cache',
        body: JSON.stringify(data)
    }).then(response => API.parseResponse(response)),

    registrationLog: (user) => API.fetch("/api/log.php ", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=UTF-8'
        },
        cache: 'no-cache',
        body: JSON.stringify(user)
    }).then(response => API.parseResponse(response)),

    login: (user) => API.fetch(CONFIG.API + "/login", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=UTF-8'
        },
        cache: 'no-cache',
        body: JSON.stringify(user)
    }).then(response => API.parseResponse(response)),

    logout: (userSession) => API.fetch(CLOUDX_NEOS_API + "/api/userSessions/" + userSession.userId + "/" + userSession.token, {
        method: 'DELETE',
        headers: {
            'Authorization': 'neos ' + userSession.userId + ':' + userSession.token
        },
        cache: 'no-cache'
    }).then(response => API.parseResponse(response)),

    getUser: (apiToken) => API.fetch(CONFIG.API + "/user/", {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            'Authorization': apiToken
        },
        cache: 'no-cache'
    }).then(response => API.parseResponse(response)),

    changePatreonEmail: (apiToken, data) => API.fetch(CONFIG.API + "/user/changePatreonEmail", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            'Authorization': apiToken
        },
        cache: 'no-cache',
        body: JSON.stringify(data)
    }).then(response => API.parseResponse(response)),

    getStoragePlan: (apiToken) => API.fetch(CONFIG.API + "/storage-plan/list", {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            'Authorization': apiToken
        },
        cache: 'no-cache'
    }).then(response => API.parseResponse(response)),

    cancelStoragePlan: (apiToken) => API.fetch(CONFIG.API + "/storage-plan/cancel", {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            'Authorization': apiToken
        },
        cache: 'no-cache'
    }).then(response => API.parseResponse(response)),

    saveStoragePlan: (apiToken, data) => API.fetch(CONFIG.API + "/storage-plan/send", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            'Authorization': apiToken
        },
        cache: 'no-cache',
        body: JSON.stringify(data)
    }).then(response => API.parseResponse(response)),

    getInventory: (apiToken) => API.fetch(CONFIG.API + "/inventory/", {
        method: 'GET',
        headers: {
            'Authorization': apiToken
        },
        cache: 'no-cache'
    }).then(response => API.parseResponse(response)),

    getInventoryItem: (apiToken, id, owner) => API.fetch(CONFIG.API + "/inventory/item/" + owner + "/" + id, {
        method: 'GET',
        headers: {
            'Authorization': apiToken
        },
        cache: 'no-cache'
    }).then(response => API.parseResponse(response)),

    getInventoryOwnerDirectory: (apiToken, id, owner) => API.fetch(CONFIG.API + "/inventory/directory/" + owner + "/"+encodeURIComponent(id), {
        method: 'GET',
        headers: {
            'Authorization': apiToken
        },
        cache: 'no-cache'
    }).then(response => API.parseResponse(response)),

    getFriends: (userSession) => API.fetch(CLOUDX_NEOS_API + "/api/users/" + userSession.userId + "/friends", {
        method: 'GET',
        headers: {
            'Authorization': 'neos ' + userSession.userId + ':' + userSession.token
        },
        cache: 'no-cache'
    }).then(response => API.parseResponse(response)),

    getMessages: (userSession, choosedFriend) => API.fetch(CLOUDX_NEOS_API + "/api/users/" + userSession.userId + "/messages?maxItems=100&user=" + choosedFriend, {
        method: 'GET',
        headers: {
            'Authorization': 'neos ' + userSession.userId + ':' + userSession.token
        },
        cache: 'no-cache'
    }).then(response => API.parseResponse(response)),

    sendMessage: (userSession, choosedFriend, content) => API.fetch(CLOUDX_NEOS_API + "/api/users/" + choosedFriend + "/messages", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            'Authorization': 'neos ' + userSession.userId + ':' + userSession.token
        },
        cache: 'no-cache',
        body: JSON.stringify({
            "MessageType": "text",
            "Content": content
        })
    }).then(response => API.parseResponse(response)),



    getStardustLevels: (apiToken) => API.fetch(CONFIG.API + "/stardust-level/list", {
        method: 'GET',
        headers: {
            'Authorization': apiToken
        },
        cache: 'no-cache'
    }).then(response => API.parseResponse(response)),


    getTransactions: (apiToken) => API.fetch(CONFIG.API + "/transaction/list", {
        method: 'GET',
        headers: {
            'Authorization': apiToken
        },
        cache: 'no-cache'
    }).then(response => API.parseResponse(response)),


    getAllTransactions: (apiToken) => API.fetch(CONFIG.API + "/transaction/listAll", {
        method: 'GET',
        headers: {
            'Authorization': apiToken
        },
        cache: 'no-cache'
    }).then(response => API.parseResponse(response)),

    getTransactionFee: (apiToken) => API.fetch(CONFIG.API + "/transaction/fee", {
        method: 'GET',
        headers: {
            'Authorization': apiToken
        },
        cache: 'no-cache'
    }).then(response => API.parseResponse(response)),

    sendTransaction: (apiToken, data) => API.fetch(CONFIG.API + "/transaction/send", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            'Authorization': apiToken
        },
        cache: 'no-cache',
        body: JSON.stringify(data)
    }).then(response => API.parseResponse(response)),




    getNotifications: (apiToken) => API.fetch(CONFIG.API + "/notification/list", {
        method: 'GET',
        headers: {
            'Authorization': apiToken
        },
        cache: 'no-cache'
    }).then(response => API.parseResponse(response)),

    setAsReaded: (apiToken) => API.fetch(CONFIG.API + "/notification/asReaded", {
        method: 'GET',
        headers: {
            'Authorization': apiToken
        },
        cache: 'no-cache'
    }).then(response => API.parseResponse(response)),


    getAllNotifications: (apiToken) => API.fetch(CONFIG.API + "/notification/listAll", {
        method: 'GET',
        headers: {
            'Authorization': apiToken
        },
        cache: 'no-cache'
    }).then(response => API.parseResponse(response)),
};

export default API;