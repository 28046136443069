import React, {Component} from 'react';
import {connect} from 'react-redux';
import {login, logout, setFlashError, lostPassword} from '../../services/user/actions';
import secretID from "../../services/secretID";
import {NavLink} from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";

class LostPassword extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showLoginModal: false,
            isRobot: true,
            credentials: {},

            toHome: false
        };
        document.title = "Neos Account - Lost Passwrod";


        this.handleLostPassword = this.handleLostPassword.bind(this);
        this.onChangeRecaptcha = this.onChangeRecaptcha.bind(this);
        this.onErrorRecaptcha = this.onErrorRecaptcha.bind(this);

    }

    onChangeRecaptcha(value) {
        this.setState({isRobot: false});
    }

    onErrorRecaptcha(value) {
        this.setState({isRobot: true});
    }

    handleLostPassword(event) {
        event.preventDefault();
        this.props.setFlashError(null);
        let _self = this;
        const user = new FormData(event.target);

        if (this.state.isRobot) {
            this.props.setFlashError("Are you really not a robot?");
            return;
        }

        if (!secretID.get()) {
            secretID.generate();
        }

        const data = {
            Email: user.get("email"),
        };

        this.props.lostPassword(data).then(function () {
            if (!_self.props.flashError) {
                window.location.href="/ui/resetpassword";
            }
        });
    }

    render() {
        const {flashError} = this.props;

        return (
            <div className="App-vertical-center">
            <div className="App-login App-box App-box-light">

                <form onSubmit={this.handleLostPassword} className="form">
                    <h1 className="App-title">Lost Password</h1>

                    {flashError && (
                        <p className="text-danger">{flashError}</p>
                    )}

                    <div className="form-group">
                        <input type="text" name="email" placeholder="E-mail" className="App-input"
                               required="required"/>
                    </div>
                    <div className="form-group">
                    <ReCAPTCHA
                        sitekey="6LfIwLoZAAAAAA2cBRLcFWuwd1PbfRuklUFLwZTK"
                        onExpired={this.onErrorRecaptcha}
                        onErrored={this.onErrorRecaptcha}
                        onChange={this.onChangeRecaptcha}
                    />
                    </div>
                    <button type="submit" className="App-button w-100"><span>Send me code</span></button>

                </form>
            </div>
            </div>
        );
    }
}

const mapStateToProps = function (state) {
    return {
        logged: state.user.logged,
        user: state.user.user,
        is2FA: state.user.is2FA,
        credentials: state.user.credentials,
        flashError: state.user.flashError
    }
};

export default connect(mapStateToProps, {
    login, logout, setFlashError, lostPassword
})(LostPassword);