import {USER} from "./actionTypes";
import API from "../../api";
import neos from "../neos";
import {TRANSACTION} from "../transaction/actionTypes";
import {clear2FA, clearFlashError, loadTransactions, receiveTransactions} from "../transaction/actions";

export const registration = user => (
    (dispatch) => (
        API.registration(user)
            .then((data) => (
                true
            )).catch(function (error) {
            dispatch(setFlashError(error.toString()));
        })
    )
);

export const registrationLog = user => (
    (dispatch) => (
        API.registrationLog(user)
            .then((data) => (
                true
            )).catch(function (error) {
            dispatch(setFlashError(error.toString()));
        })
    )
);

export const lostPassword = data => (
    (dispatch) => (
        API.lostPassword(data)
            .then((data) => (
                true
            )).catch(function (error) {
            dispatch(setFlashError(error.toString()));
        })
    )
);

export const resetPassword = data => (
    (dispatch) => (
        API.resetPassword(data)
            .then((data) => (
                true
            )).catch(function (error) {
            dispatch(setFlashError(error.toString()));
        })
    )
);

export const login = user => (
    (dispatch) => (
        API.login(user)
            .then((apiToken) => (
                dispatch(receiveApiToken(user, apiToken, dispatch))
            )).catch(function (error) {
            dispatch(setFlashError(error.toString()));
        })
    )
);

export const logout = apiToken => {
    return {
        type: USER.LOGOUT
    };
    /*
    (dispatch) => {
        API.logout(apiToken).catch(function (error) {
            dispatch(setFlashError(error.toString()));
        });

        dispatch({
            type: USER.LOGOUT
        });
    }*/
};

export const receiveApiToken = (user, apiToken, dispatch) => {
    if (typeof apiToken === "string" && apiToken == "2FA RUN") {
        return {
            type: USER.RECIVE_2FA,
            payload: {
                credentials: user
            }
        };
    }

    if (!("token" in apiToken)) {
        dispatch(setFlashError("API token not found"));
        return;
    }
    let token = 'Bearer ' + apiToken.token;

    API.getUser(token)
        .then((user) => (
            dispatch(receiveUser(user))
        )).catch(function (error) {
        dispatch(setFlashError(error.toString()));
    });
    return {
        type: USER.RECIVE_API_TOKEN,
        payload: {
            apiToken: token
        }
    };
};

export const refreshUser = apiToken => (
    (dispatch) => (
        API.getUser(apiToken)
            .then((user) => (
                dispatch(receiveUser(user))
            )).catch(function (error) {
            dispatch(logout(apiToken))
            dispatch(setFlashError(error.toString()));
        })
    )
);

export const changePatreonEmail = (apiToken, data, callback) => (
    (dispatch) => (
        API.changePatreonEmail(apiToken, data)
            .then((result) => (
                callback(result)
            )).catch(function (error) {
            dispatch(setFlashError(error.toString()));
        })
    )
);

export const saveStoragePlan = (apiToken, data, onSubmit) => (
    (dispatch) => (
        API.saveStoragePlan(apiToken, data)
            .then((data) => {
                if (data === "2FA RUN") {
                    return dispatch({
                        type: USER.RECIVE_2FA,
                        payload: {
                            credentials: {}
                        }
                    });
                }
                if (onSubmit) onSubmit();
                dispatch(clearFlashError());
                dispatch(clear2FA());
                return dispatch(loadStoragePlan(apiToken))
            }).catch(function (error) {
            return dispatch(setFlashError(error.toString()));
        })
    )
);
export const cancelStoragePlan = (apiToken) => (
    (dispatch) => (
        API.cancelStoragePlan(apiToken)
            .then(() => {
                dispatch(clearFlashError());
                dispatch(clear2FA());
                return dispatch(loadStoragePlan(apiToken))
            }).catch(function (error) {
            dispatch(setFlashError(error.toString()));
        })
    )
);
export const loadStoragePlan = apiToken => (
    (dispatch) => (
        API.getStoragePlan(apiToken)
            .then((storagePlane) => (
                dispatch(receiveStoragePlan(storagePlane.storagePlane))
            )).catch(function (error) {
            dispatch(setFlashError(error.toString()));
        })
    )
);
export const receiveStoragePlan = storagePlane => (
    {
        type: USER.RECIVE_STORAGE_PLAN,
        payload: {
            storagePlane: storagePlane
        }
    }
);

export const receiveUser = user => {
    user.avatar = neos.getAvatar(user);

    return {
        type: USER.RECIVE,
        payload: {
            user: user
        }
    }
};

export const loadInventory = apiToken => (
    (dispatch) => (
        API.getInventory(apiToken)
            .then((inventory) => (
                dispatch({
                    type: USER.RECIVE_INVENTORY,
                    payload: {
                        inventory: inventory
                    }
                })
            )).catch(function (error) {
            dispatch(setFlashError(error.toString()));
        })
    )
);

export const loadInventoryOwnerDirectory = (apiToken, id, owner) => (
    (dispatch) => (
        API.getInventoryOwnerDirectory(apiToken, id, owner)
            .then((inventoryDirectory) => (
                dispatch({
                    type: USER.RECIVE_INVENTORY_DIRECTORY,
                    payload: {
                        inventoryDirectoryActualPath: id,
                        inventoryDirectory: inventoryDirectory
                    }
                })
            )).catch(function (error) {
            dispatch(setFlashError(error.toString()));
        })
    )
);

export const loadInventoryLink = (apiToken, id, owner) => (
    (dispatch) => (
        API.getInventoryItem(apiToken, id, owner)
            .then((inventoryItem) => (
                API.getInventoryOwnerDirectory(apiToken, inventoryItem.name, owner)
                    .then((inventoryDirectory) => (
                        dispatch({
                            type: USER.RECIVE_INVENTORY_DIRECTORY,
                            payload: {
                                inventoryDirectoryActualPath: inventoryItem.name,
                                inventoryDirectory: inventoryDirectory
                            }
                        })
                    )).catch(function (error) {
                    dispatch(setFlashError(error.toString()));
                })
            )).catch(function (error) {
            dispatch(setFlashError(error.toString()));
        })
    )
);

export const loadFriends = apiToken => (
    (dispatch) => (
        API.getFriends(apiToken)
            .then((friends) => (
                dispatch({
                    type: USER.RECIVE_FRIENDS,
                    payload: {
                        friends: friends
                    }
                })
            )).catch(function (error) {
            dispatch(setFlashError(error.toString()));
        })
    )
);

export const loadMessages = (apiToken, choosedFriend) => (
    (dispatch) => (
        API.getMessages(apiToken, choosedFriend)
            .then((messages) => (
                dispatch({
                    type: USER.RECIVE_MESSAGES,
                    payload: {
                        messages: messages
                    }
                })
            )).catch(function (error) {
            dispatch(setFlashError(error.toString()));
        })
    )
);

export const sendMessage = (apiToken, choosedFriend, content) => (
    (dispatch) => {
        dispatch({
            type: USER.SEND_START_MESSAGE
        });

        return API.sendMessage(apiToken, choosedFriend, content)
            .then((messages) => {
                dispatch({
                    type: USER.SEND_END_MESSAGE
                });

                return dispatch({
                    type: USER.RECIVE_MESSAGES,
                    payload: {
                        messages: messages
                    }
                })
            }).catch(function (error) {
                dispatch({
                    type: USER.SEND_END_MESSAGE
                });

                dispatch(setFlashError(error.toString()));
            });
    }
);

export const resetInventoryDirectory = () => ({
    type: USER.RESET_INVENTORY_DIRECTORY
});

export const setFlashError = (error) => {
    /*if (error == "JWT Token not found") {
        return {
            type: USER.LOGOUT
        };
    }*/

    return {
        type: USER.FLASH_ERROR,
        payload: {
            error: error
        }
    }
};

export const setChoosedFriend = friend => ({
    type: USER.CHOOSE_FRIEND,
    payload: {
        friend: friend
    }
});

export const checkValidJwt = (response, dispatch, callback) => {
    if ("message" in response && response.message == "JWT Token not found") {
        return dispatch({
            type: USER.LOGOUT
        });
    }
    return callback();
}