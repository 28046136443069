import {NOTIFICATION} from "./actionTypes";
import API from "../../api";

export const receiveNotifications = (notifications, unreaded) => (
    {
        type: NOTIFICATION.RECIVE,
        payload: {
            notifications: notifications,
            unreaded: unreaded
        }
    }
);
export const receiveAllNotifications = notifications => (
    {
        type: NOTIFICATION.RECIVE_ALL,
        payload: {
            notifications_all: notifications
        }
    }
);
export const loadNotifications = apiToken => (
    (dispatch) => (
        API.getNotifications(apiToken)
            .then((notifications) => (
                dispatch(receiveNotifications(notifications.notifications, notifications.unreaded))
            )).catch(function (error) {
            dispatch(setFlashError(error.toString()));
        })
    )
);
export const setAsReaded = apiToken => (
    (dispatch) => (
        API.setAsReaded(apiToken)
            .then((notifications) => (
                dispatch(receiveNotifications(notifications.notifications, notifications.unreaded))
            )).catch(function (error) {
            dispatch(setFlashError(error.toString()));
        })
    )
);
export const loadAllNotifications = apiToken => (
    (dispatch) => (
        API.getAllNotifications(apiToken)
            .then((notifications) => (
                dispatch(receiveAllNotifications(notifications))
            )).catch(function (error) {
            dispatch(setFlashError(error.toString()));
        })
    )
);

export const setFlashError = (error) => {
    return {
        type: NOTIFICATION.FLASH_ERROR,
        payload: {
            error: error
        }
    }
};
export const clearFlashError = () => {
    return {
        type: NOTIFICATION.CLEAR_FLASH_ERROR
    }
};