import {TRANSACTION} from "./actionTypes";

const initialState = {
    transactions: {},
    transactions_all: {},
    is2FA: false,
    flashError: false,
    fee: 50,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case TRANSACTION.FEE: {
            const {fee} = action.payload;

            return {
                ...state,
                fee: fee,
            }
        }
        case TRANSACTION.RECIVE: {
            const {transactions} = action.payload;

            return {
                ...state,
                transactions: transactions,
            }
        }
        case TRANSACTION.RECIVE_ALL: {
            const {transactions_all} = action.payload;

            return {
                ...state,
                transactions_all: transactions_all,
            }
        }
        case TRANSACTION.FLASH_ERROR: {
            const {error} = action.payload;

            return {
                ...state,
                flashError: error
            }
        }
        case TRANSACTION.CLEAR_FLASH_ERROR: {
            return {
                ...state,
                flashError: false
            }
        }
        case TRANSACTION.RECIVE_2FA: {
            return {
                ...state,
                is2FA: true
            }
        }
        case TRANSACTION.CLEAR_2FA: {
            console.log("---");
            return {
                ...state,
                is2FA: false
            }
        }
        default:
            return state;
    }
}
