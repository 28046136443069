import {STARDUST_LEVEL} from "./actionTypes";

const initialState = {
    stardustLevels: {},
    flashError: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case STARDUST_LEVEL.RECIVE: {
            const {stardustLevels} = action.payload;

            return {
                ...state,
                stardustLevels: stardustLevels,
            }
        }
        case STARDUST_LEVEL.FLASH_ERROR: {
            const {error} = action.payload;

            return {
                ...state,
                flashError: error
            }
        }
        case STARDUST_LEVEL.CLEAR_FLASH_ERROR: {
            return {
                ...state,
                flashError: false
            }
        }
        default:
            return state;
    }
}
