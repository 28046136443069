import {STARDUST_LEVEL} from "./actionTypes";
import API from "../../api";

export const receiveStardustLevels = (stardustLevels) => (
    {
        type: STARDUST_LEVEL.RECIVE,
        payload: {
            stardustLevels: stardustLevels
        }
    }
);
export const loadStardustLevels = apiToken => (
    (dispatch) => (
        API.getStardustLevels(apiToken)
            .then((stardustLevels) => (
                dispatch(receiveStardustLevels(stardustLevels.stardustLevels))
            )).catch(function (error) {
            dispatch(setFlashError(error.toString()));
        })
    )
);
export const setFlashError = (error) => {
    return {
        type: STARDUST_LEVEL.FLASH_ERROR,
        payload: {
            error: error
        }
    }
};
export const clearFlashError = () => {
    return {
        type: STARDUST_LEVEL.CLEAR_FLASH_ERROR
    }
};