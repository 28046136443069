import {LOADER} from "./actionTypes";

const initialState = {
    runningLoads: 0
};

export default function (state = initialState, action) {
    switch (action.type) {
        case LOADER.DEGREASE: {
            return {
                ...state,
                runningLoads: state.runningLoads - 1
            }
        }
        case LOADER.INCREASE: {
            return {
                ...state,
                runningLoads: state.runningLoads + 1
            }
        }
        case LOADER.RESET: {
            return {
                ...state,
                runningLoads: 0
            }
        }
        default:
            return state;
    }
}
