import React, {Component} from 'react';
import {connect} from 'react-redux';
import {registration, registrationLog, login, logout, setFlashError} from '../../services/user/actions';
import secretID from "../../services/secretID";
import {NavLink} from "react-router-dom";

class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showLoginModal: false,
            showRegistrationModal: false,
            showAfterRegistrationModal: false,
            isRobot: true,
            credentials: {},

            toHome: false
        };
        document.title = "Neos Account - Login";

        this.handleLogin = this.handleLogin.bind(this);
        this.handle2FA = this.handle2FA.bind(this);
    }

    handleLogin(event) {
        event.preventDefault();
        this.props.setFlashError(null);
        let _self = this;
        const user = new FormData(event.target);

        if (!secretID.get()) {
            secretID.generate();
        }

        const data = {
            password: user.get("password"),
            secretMachineId: secretID.get(),
        };

        const credential = user.get("credential");

        if (credential.startsWith("U-"))
            data.OwnerId = credential;
        else if (credential.includes("@"))
            data.Email = credential;
        else
            data.Username = credential;

        data.username = credential;

        this.setState({credential: data});


        this.props.login(data);
    }

    handle2FA(event) {
        event.preventDefault();

        this.props.setFlashError(null);

        let _self = this;
        let credentials = this.props.credentials;

        const form = new FormData(event.target);
        credentials.code2FA = form.get("code2FA")

        this.props.login(credentials);
    }

    render() {
        const {flashError, is2FA} = this.props;

        return (
            <div className="App-vertical-center">
            <div className="App-login App-box App-box-light">

                    {is2FA ? (
                        <form onSubmit={this.handle2FA} className="form">
                            <h1 className="App-title">2 Factor Authentification</h1>

                            {flashError && (
                                <p className="text-danger">{flashError}</p>
                            )}

                            <div className="form-group">
                                <input type="text" name="code2FA" placeholder="Enter code" className="App-input"
                                       required="required"/>
                            </div>
                            <button type="submit" className="App-button w-100"><span>Confirm</span></button>
                        </form>
                    ) : (
                        <form onSubmit={this.handleLogin} className="form">
                            <h1 className="App-title">Log in</h1>

                            {flashError && (
                                <p className="text-danger">{flashError}</p>
                            )}

                            <div className="form-group">
                                <input type="text" name="credential" placeholder="Account name or email" className="App-input"
                                       required="required"/>
                            </div>
                            <div className="form-group">
                                <input type="password" name="password" placeholder="Password" className="App-input"
                                       required="required"/>
                            </div>
                            <button type="submit" className="App-button w-100"><span>Login</span></button>

                            <div className="App-login__bottom text-center">
                                Don’t have an account? <NavLink to="/register">Create an account</NavLink>
                                <br/>
                                <NavLink to="/lostpassword">Lost your password?</NavLink>
                            </div>
                        </form>
                    )}
            </div>
            </div>
        );
    }
}

const mapStateToProps = function (state) {
    return {
        logged: state.user.logged,
        user: state.user.user,
        is2FA: state.user.is2FA,
        credentials: state.user.credentials,
        flashError: state.user.flashError
    }
};

export default connect(mapStateToProps, {
    registration, login, logout, setFlashError, registrationLog
})(Login);