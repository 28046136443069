import {USER} from "./actionTypes";

const initialState = {
    user: false,
    userSession: false,
    apiToken: false,
    logged: false,
    is2FA: false,
    credentials: {},
    flashError: false,
    inventory: [],
    inventoryDirectory: [],
    inventoryDirectoryActualPath: "",
    friends: [],
    choosedFriend: null,
    messages: [],
    sending: false,
    storagePlane: false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case USER.RECIVE: {
            const {user} = action.payload;

            if (!user) {
                return state;
            }

            return {
                ...state,
                user: user,
                logged: true,
                is2FA: false,
                credentials: {},
                flashError: false
            }
        }
        case USER.RECIVE_2FA: {
            const {credentials} = action.payload;

            return {
                ...state,
                is2FA: true,
                credentials: credentials,
            }
        }
        case USER.RECIVE_SESSION: {
            const {userSession} = action.payload;

            if (!userSession) {
                return state;
            }

            return {
                ...state,
                userSession: userSession,
                logged: true,
                flashError: false
            }
        }
        case USER.RECIVE_STORAGE_PLAN: {
            const {storagePlane} = action.payload;

            if (!storagePlane) {
                return state;
            }

            return {
                ...state,
                storagePlane: storagePlane
            }
        }
        case USER.RECIVE_API_TOKEN: {
            const {apiToken} = action.payload;

            if (!apiToken) {
                return state;
            }

            return {
                ...state,
                apiToken: apiToken,
            }
        }
        case USER.RECIVE_INVENTORY: {
            const {inventory} = action.payload;

            if (!inventory) {
                return state;
            }

            return {
                ...state,
                inventory: inventory,
                flashError: false
            }
        }
        case USER.RECIVE_INVENTORY_DIRECTORY: {
            const {inventoryDirectory, inventoryDirectoryActualPath} = action.payload;

            if (!inventoryDirectory) {
                return state;
            }

            return {
                ...state,
                inventoryDirectory: inventoryDirectory,
                inventoryDirectoryActualPath: inventoryDirectoryActualPath,
                flashError: false
            }
        }
        case USER.RESET_INVENTORY_DIRECTORY: {
            return {
                ...state,
                inventoryDirectory: [],
                inventoryDirectoryActualPath: "",
                flashError: false
            }
        }
        case USER.RECIVE_FRIENDS: {
            const {friends} = action.payload;

            if (!friends) {
                return state;
            }

            let sortedFriends = friends;

            if (sortedFriends && "length" in sortedFriends && sortedFriends.length > 1) {
                sortedFriends = sortedFriends.sort(function (a, b) {
                    return (new Date(a.latestMessageTime)).getTime() < (new Date(b.latestMessageTime)).getTime();
                });
            }

            return {
                ...state,
                friends: sortedFriends,
                chooseFriend: sortedFriends[0],
                flashError: false
            }
        }
        case USER.LOGOUT: {
            return initialState
        }
        case USER.FLASH_ERROR: {
            const {error} = action.payload;

            return {
                ...state,
                flashError: error
            }
        }
        case USER.CHOOSE_FRIEND: {
            const {friend} = action.payload;

            return {
                ...state,
                choosedFriend: friend
            }
        }
        case USER.SEND_START_MESSAGE: {
            return {
                ...state,
                sending: true
            }
        }
        case USER.SEND_END_MESSAGE: {
            return {
                ...state,
                sending: false
            }
        }
        case USER.RECIVE_MESSAGES: {
            const {messages} = action.payload;

            return {
                ...state,
                messages: messages
            }
        }
        default:
            return state;
    }
}
