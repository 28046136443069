import React, {useState, useEffect} from 'react';
import './Home.sass';
import {connect} from "react-redux";
import SendTransactionModal from "../../modals/transactions/SendTransactionModal";
import DepositTransactionModal from "../../modals/transactions/DepositTransactionModal";
import MiningSelectClientModal from "../../modals/selectClient/MiningSelectClientModal";
import {loadNotifications, setAsReaded} from "../../services/notification/actions";
import {refreshUser, loadStoragePlan} from "../../services/user/actions";
import ProfileCloud from "../Profile/ProfileCloud";
import {Scrollbars} from "react-custom-scrollbars";
import {clear2FA} from "../../services/transaction/actions";
import {useLocation} from "react-router-dom";
import {loadStardustLevels} from "../../services/stardustLevel/actions";

function parseValue(value) {
    return Math.round(value / 1024 / 1024 / 1024 * 100) / 100 + " GB"
}

function Home(props) {
    const {user, apiToken, notifications, loadNotifications, unreaded, refreshUser, clear2FA, loadStoragePlan, loadStardustLevels, stardustLevels} = props;

    const [download, setDownload] = useState(false);
    const [deposit, setDeposit] = useState(false);
    const [withdraw, setWithdraw] = useState(false);
    const { hash } = useLocation();

    useEffect(() => {
        setTimeout(function (){
            props.setAsReaded(apiToken);
        }, 10*1000);
    }, []);

    useEffect(() => {
        document.title = "Neos Account";
        refreshUser(apiToken);
        loadNotifications(apiToken);
        loadStoragePlan(apiToken);
        loadStardustLevels(apiToken);
        clear2FA();
        window.dispatchEvent(new Event('resize'));
    }, []);


    useEffect(()=>{
        clear2FA();
        console.log("clear")
    }, [withdraw]);

    if (hash == '#deposit' && !deposit) {
        setDeposit(true);
    }

    useEffect(()=>{
        if (hash == '#deposit') {
            window.location.href = "#";
        }
    }, [deposit]);

    function getPercent(user) {
        return Math.round(user.stardusts_percent);
    }

    function getPercentForBlock(user, key, count) {
        const percent = getPercent(user);
        if (100/count*(key+1) < percent) {
            return 100;
        }
        if (100/count*key > percent) {
            return 0;
        }
        let left = percent - (100/count*key);
        let right = (100/count*(key+1)) - (100/count*key);

        return Math.round(left/right*100);
    }

    return (
        <div className="Home">
            <div className="App-box App-box--profile">
                <ProfileCloud/>

                <div className="d-flex">
                    <div>
                        <img className="App-profile-avatar" src={user.avatar} width="146" height="146"
                             alt={user.username}/>
                    </div>
                    <div>
                        <h1 className="App-title">
                            {user.username}

                            <span className="App-title__icons">
                                <img src={"/ui/assets/stardust_levels/"+user.stardust_level.id+".svg"} width="36" height="36"/>
                            </span>
                        </h1>
                        <div className="App-profile-info">
                            <span className="color-gray">Status: </span>
                            <span>Registered</span> <br/>
                            <span className="color-gray">Neos Credits: </span>
                            <span>{"credits" in user && "NCR" in user.credits ? Math.round(user.credits.NCR*100)/100 : "0"} NCR</span>
                        </div>
                        <div className="App-profile-trans-buttons">
                            {/*
                            <button className="button button--primary" onClick={() => setDeposit(true)}>
                                <span>Deposit</span></button>
                                */}
                            <button className="button button--secondary" onClick={() => setWithdraw(true)}>
                                <span>Send</span></button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="App-stardust-line">
                <div className="App-stardust-line__left">{user.stardust_level.name}</div>
                <div className="App-stardust-line__center">Stardust {user.stardusts} / {user.stardust_level_after.stardusts}</div>
                <div className="App-stardust-line__right">{user.stardust_level_after.name}</div>
                <div className="App-stardust-line__content">
                    {[...Array(9).keys()].map(i => (
                        <span>
                            <span style={{width: getPercentForBlock(user, i, 9)+"%"}}></span>
                        </span>
                    ))}
                </div>
            </div>

            <div className="row">
                <div className="col-7">

                    <div className="App-box">
                        <h2 className="App-subtitle">Stardust</h2>

                        <table className="App-stardust-table">
                            <thead>
                            <tr>
                                <th>Rank</th>
                                <th>Staking percentage</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>
                                    <span className="App-stardust-name">
                                        <img src={"/ui/assets/stardust_levels/"+user.stardust_level.id+".svg"} width="49" height="49"
                                             alt=""/>
                                        <span>{user.stardust_level.name} <sup>{user.stardust_level.id}</sup></span>
                                    </span>
                                </td>
                                <td>{Math.round(user.stardust_level.percentage*100)/100}%</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="App-box">
                        <div className="App-box-planet"></div>
                        <h2 className="App-subtitle mb-12">Cooper’s Planet</h2>
                        <p>
                            <a href="#" onClick={()=> {setDownload(true);return false}} className="link link--next">Mining</a>
                        </p>
                        <div className="App-box__buttons">
                            <a href="http://cloudx.azurewebsites.net/open/world/G-Neos/R-Hub" target="_blank" className="button button--secondary"><span
                                className="button__library">Neos Hub</span></a>
                            <a href="http://cloudx.azurewebsites.net/open/world/G-Neos-Team/R-6eff26cf-dc1a-4519-91ea-0beb2e812f70" target="_blank" className="button button--secondary"><span className="button__teach">Training Center</span></a>
                        </div>
                    </div>

                </div>
                <div className="col-5">
                    <div className="App-box h100">
                        <Scrollbars
                            renderThumbVertical={({style, ...props}) => {
                                const thumbStyle = {
                                    backgroundColor: `rgba(200, 200, 255, .2)`,
                                    borderRadius: "3px"
                                };
                                return (
                                    <div
                                        style={{...style, ...thumbStyle}}
                                        {...props}/>
                                );
                            }}
                            className="main__inner" style={{width: "100%", height: "100%"}}>
                            <div className="App-box__inner">
                            <h2 className="App-subtitle">Notifications <small>({unreaded})</small></h2>

                            {Array.from(notifications).map((t, t_key) => (
                                <div className="App-notification" key={t_key}>
                                    <input type="checkbox" id={"notification-"+t_key}/>

                                    <label for={"notification-"+t_key}>
                                    <div className="App-notification-left">
                                        {t.type === "stardust-level-up" ? (
                                            stardustLevels && stardustLevels.length > 0 && stardustLevels.map((stardustLevel)=> (
                                                stardustLevel.name === t.message2 ? (
                                                    <img src={"/ui/assets/stardust_levels/"+stardustLevel.id+".svg"} alt="" className="App-notification-ico"/>
                                                ) : ""
                                            ))
                                        ) : (
                                            <div
                                                className={"App-notification-ico App-notification-ico--" + t.type}></div>
                                        )}
                                        <div className="App-notification-message">
                                            {"message3" in t && t.message3 != "" && (
                                                <span className="App-notification-dropdown"></span>
                                            )}
                                            {t.message}
                                        </div>
                                    </div>
                                    <div className="App-notification-message2">{t.message2}</div>
                                    </label>
                                    {"message3" in t && t.message3 != "" && (
                                        <div className="on-checked App-notification-message3">
                                            <div dangerouslySetInnerHTML={{__html: t.message3}}/>
                                        </div>
                                    )}
                                </div>
                            ))}
                            </div>
                        </Scrollbars>
                    </div>
                </div>
            </div>

            <MiningSelectClientModal isOpen={download} close={() => setDownload(false)}/>

            {/*
            <DepositTransactionModal isOpen={deposit} close={() => setDeposit(false)}/>
            */}
            <SendTransactionModal isOpen={withdraw} close={() => setWithdraw(false)}/>
        </div>
    );
}

const mapStateToProps = function (state) {
    return {
        user: state.user.user,
        apiToken: state.user.apiToken,
        notifications: state.notification.notifications,
        unreaded: state.notification.unreaded,
        stardustLevels: state.stardustLevel.stardustLevels,
    }
};
export default connect(mapStateToProps, {setAsReaded, loadNotifications, loadStoragePlan, refreshUser, clear2FA, loadStardustLevels})(Home);