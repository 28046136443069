import {TRANSACTION} from "./actionTypes";
import API from "../../api";

export const receiveTransactions = transactions => (
    {
        type: TRANSACTION.RECIVE,
        payload: {
            transactions: transactions
        }
    }
);
export const receiveAllTransactions = transactions => (
    {
        type: TRANSACTION.RECIVE_ALL,
        payload: {
            transactions_all: transactions
        }
    }
);
export const loadTransactions = apiToken => (
    (dispatch) => (
        API.getTransactions(apiToken)
            .then((transactions) => (
                dispatch(receiveTransactions(transactions.transactions))
            )).catch(function (error) {
            dispatch(setFlashError(error.toString()));
        })
    )
);
export const loadAllTransactions = apiToken => (
    (dispatch) => (
        API.getAllTransactions(apiToken)
            .then((transactions) => (
                dispatch(receiveAllTransactions(transactions))
            )).catch(function (error) {
            dispatch(setFlashError(error.toString()));
        })
    )
);

export const sendTransaction = (apiToken, data, onSubmit) => (
    (dispatch) => (
        API.sendTransaction(apiToken, data)
            .then((data) => {
                if (data === "2FA RUN") {
                    return dispatch({
                        type: TRANSACTION.RECIVE_2FA
                    });
                }
                if (onSubmit) onSubmit();
                dispatch(clearFlashError());
                dispatch(clear2FA());
                return dispatch(loadTransactions(apiToken))
            }).catch(function (error) {
            return dispatch(setFlashError(error.toString()));
        })
    )
);

export const getTransactionFee = (apiToken, callback) => (
    (dispatch) => (
        API.getTransactionFee(apiToken)
            .then((data) => {
                if (callback) {
                    callback(data.fee);
                }

                return dispatch({
                    type: TRANSACTION.FEE,
                    payload: {
                        fee: data.fee
                    }
                });
            }).catch(function (error) {
            return dispatch(setFlashError(error.toString()));
        })
    )
);

export const setFlashError = (error) => {
    return {
        type: TRANSACTION.FLASH_ERROR,
        payload: {
            error: error
        }
    }
};
export const clearFlashError = () => {
    return {
        type: TRANSACTION.CLEAR_FLASH_ERROR
    }
};
export const clear2FA = () => {
    return (dispatch) => {
        dispatch(clearFlashError());
        dispatch({
            type: TRANSACTION.CLEAR_2FA
        });
        return {
            type: TRANSACTION.CLEAR_2FA
        }
    }
};