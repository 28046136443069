import React, {Component} from 'react';
import {NavLink} from "react-router-dom";

class InventoryOwnerDirectory extends Component {

    render() {
        const {data, inventoryDirectoryActualPath} = this.props;
        const name = encodeURIComponent(encodeURIComponent(data.name));
        const actualPath = encodeURIComponent(encodeURIComponent(inventoryDirectoryActualPath));

        let link = "/inventory/directory/" + data.ownerId + "/" + name;
        if (inventoryDirectoryActualPath && inventoryDirectoryActualPath !== "") {
            link = "/inventory/directory/" + data.ownerId + "/" + actualPath + encodeURIComponent("%5C") + name;
        }

        return (
            <NavLink to={link} className="App-inventory-box App-inventory-box--mini-ico">
                <div>
                    <img src="/ui/assets/images/icon/folder.svg" width="28" height="24" alt="Folder"/>
                </div>
                <h3 className="App-inventory-box-link">
                    {data.name}
                </h3>
            </NavLink>
        );
    }
}

export default InventoryOwnerDirectory;