import ReactModal from "react-modal";
import React from "react";
import SendTransactionForm from "../../forms/transactions/SendTransactionForm";

function SendTransactionModal(props) {
    const {isOpen, close} = props;
    return (
        <ReactModal
            isOpen={isOpen}
            onRequestClose={close}
            ariaHideApp={false}
            style={{content: {background: "none", border: "none"}, overlay: {background: "rgba(0,0,0,.6)"}}}
        >
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h2 className="App-title">Send</h2>
                    </div>
                    <div className="modal-body">
                        <SendTransactionForm onSubmit={close} isOpen={isOpen}/>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="button button--secondary" data-dismiss="modal"
                                onClick={close}>
                            <span>Close</span>
                        </button>
                    </div>
                </div>
            </div>
        </ReactModal>
    );
}

export default SendTransactionModal;