import React, {Component} from 'react';
import FriendItem from "./FriendItem";

class FriendsList extends Component {

    render() {
        const {friends} = this.props;

        return (
            <div className="Messages-FriendsList">
                {friends && "length" in friends && friends.length > 0 && (
                    <div className="Inventory">
                        {friends.map((friend, index) => (
                            <FriendItem key={index} friend={friend} />
                        ))}
                    </div>
                )}
            </div>
        );
    }
}

export default FriendsList;