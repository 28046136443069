import {NOTIFICATION} from "./actionTypes";

const initialState = {
    unreaded: 0,
    notifications: {},
    notifications_all: {},
    flashError: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case NOTIFICATION.RECIVE: {
            const {notifications, unreaded} = action.payload;

            return {
                ...state,
                notifications: notifications,
                unreaded: unreaded,
            }
        }
        case NOTIFICATION.RECIVE_ALL: {
            const {notifications_all} = action.payload;

            return {
                ...state,
                notifications_all: notifications_all,
            }
        }
        case NOTIFICATION.FLASH_ERROR: {
            const {error} = action.payload;

            return {
                ...state,
                flashError: error
            }
        }
        case NOTIFICATION.CLEAR_FLASH_ERROR: {
            return {
                ...state,
                flashError: false
            }
        }
        default:
            return state;
    }
}
