import React, {Component} from 'react';
import neos from "../../../services/neos";
import {connect} from "react-redux";
import {setChoosedFriend} from "../../../services/user/actions";

class FriendItem extends Component {

    constructor(props) {
        super(props);

        this.onClickFriend = this.onClickFriend.bind(this);
    }

    onClickFriend() {
        this.props.setChoosedFriend(this.props.friend.id);
    }

    render() {
        const {friend} = this.props;

        return (
            <div className={"Messages-FriendItem " + (this.props.choosedFriend === friend.id ? "Messages-FriendItem--selected" : "")} onClick={this.onClickFriend}>
                <img src={neos.getAvatar(friend)} alt={friend.username}/>
                {friend.friendUsername}
            </div>
        );
    }
}

const mapStateToProps = function (state) {
    return {
        choosedFriend: state.user.choosedFriend
    }
};

export default connect(mapStateToProps, {setChoosedFriend})(FriendItem);