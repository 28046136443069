import React, {Component} from 'react';
import neos from "../../../services/neos";

class InventoryItem extends Component {

    render() {
        const {data} = this.props;
        let img = "//via.placeholder.com/350x150?text=none";

        if (data.thumbnailUri) {
            img = neos.neosDbToHttp(data.thumbnailUri);
        }

        return (
            <div className="App-inventory-box App-inventory-box--big-ico">
                <img src={img} alt={data.name} className="responsive-img"/>
                <h3 className="App-inventory-box-title">{data.name}</h3>
            </div>
        );
    }
}

export default InventoryItem;