import React, {Component} from 'react';
import { Link } from "react-router-dom";

class NotFound extends Component {

    componentDidMount() {
        document.title = "Page not found";
    }

    render() {
        return (
            <div className="App-container">
                <div className="App-top">
                <div className="entry-content">
                    <h1 className="App-title">Page not found</h1>
                    <p className="text-white">
                        We're sorry :(
                    </p>
                </div>
                </div>
            </div>
        );
    }
}

export default NotFound;
