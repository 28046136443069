import ReactModal from "react-modal";
import {React, useEffect, useState} from "react";
import {connect} from "react-redux";
import {getTransactionFee, sendTransaction} from "../../services/transaction/actions";
import {refreshUser} from "../../services/user/actions";
import {loadNotifications} from "../../services/notification/actions";

function SendTransactionForm(props) {
    const {flashError, onSubmit, sendTransaction, apiToken, refreshUser, loadNotifications, is2FA} = props;

    const [amount, setAmount] = useState("");
    const [address, setAddress] = useState("");
    const [fee, setFee] = useState(null);


    useEffect(() => {
        setFee(null);
        props.getTransactionFee(props.apiToken, (fee)=>{
            setFee(fee);
        });
    },[props.isOpen]);

    function handleSubmit(e) {
        e.preventDefault();

        // Read the form data
        const form = e.target;
        const formData = new FormData(form);
        const formJson = Object.fromEntries(formData.entries());

        setAmount(formJson.amount);
        setAddress(formJson.address);

        sendTransaction(apiToken, formJson);
    }
    function handle2FA(e) {
        e.preventDefault();

        // Read the form data
        const form = new FormData(e.target);
        const formJson = {address, amount};
        formJson.code2FA = form.get("code2FA")

        sendTransaction(apiToken, formJson, function (){
            refreshUser(apiToken);
            loadNotifications(apiToken);
            if (onSubmit) {
                onSubmit();
            }
        });
    }

    console.log(is2FA);

    return (
        <form onSubmit={(e)=> is2FA ? handle2FA(e):handleSubmit(e)} className="form">
            {flashError && (
                <p className="text-danger">{flashError}</p>
            )}

            {is2FA ? (
                <div>
                    <h3>2 Factor Authentification</h3>

                    <p>The code has been sent to your email.</p>

                    <div className="form-group">
                        <input type="text" name="code2FA" placeholder="Enter code" className="App-input"
                               required="required"/>
                    </div>
                </div>
            ) : (
                <div>
                    <div className="form-group">
                        <input type="text" name="address" placeholder="Address or username" className="App-input"
                               required="required"/>
                    </div>

                    <div className="form-group">
                        <input type="number" name="amount" step=".000000000000000001" placeholder="How much NCR" className="App-input"
                               required="required"/>
                    </div>
                </div>
            )}

            <button type="submit" className="App-button w-100"><span>Confirm</span></button>
                {fee ? (
                    <p className="mt-3 mb-0">Withdrawal would cost right now
                        &nbsp;<strong>{fee} NCR</strong>
                    </p>
                ): (
                    <p className="mt-3 mb-0">Calculating fee based on network state...</p>
                )}
        </form>
    );
}

const mapStateToProps = function (state) {
    return {
        apiToken: state.user.apiToken,
        flashError: state.transaction.flashError,
        is2FA: state.transaction.is2FA,
        fee: state.transaction.fee,
    }
};
export default connect(mapStateToProps, {sendTransaction, refreshUser, loadNotifications, getTransactionFee})(SendTransactionForm);