import React, { useEffect, useState} from 'react';
import './OAuth2.sass';
import {connect} from "react-redux";
import queryString from 'query-string';
import {refreshUser} from "../../services/user/actions";

function OAuth2(props) {
    const {user, apiToken, refreshUser, logged} = props;
    const [params, setParams] = useState(false);

    useEffect(() => {
        document.title = "Neos OAuth2";
        let params = queryString.parse(window.location.search);
        refreshUser(apiToken);
        setParams(params)
        window.dispatchEvent(new Event('resize'));
    }, [props.location, apiToken, refreshUser]);


    if (!params || !("redirect_uri" in params)) {
        return (
            <div className="Home">
                <div className="App-box App-box--profile">
                    <h1 className="App-title">
                        Redirect URI not defined.
                    </h1>
                </div>
            </div>
        );
    }

    if (logged) {
        let redirect_uri = "/ui/api/oauth2/token.php"
            + "?&user=" + user.external.userId
            + "&token=" +  user.external.sessionId
            + "&redirect_uri=" + params.redirect_uri;

        return (
            <div className="App-vertical-center">
                <div className="App-login App-box App-box-light">
                    <div className="OAuth2__continue">
                        <img src={user.avatar} alt={user.username} width="146" height="146"/>
                        <h2 className="App-subtitle mb-12">{user.username}</h2>

                        <div className="App-box__buttons">
                            <a href={redirect_uri} className="OAuth2__continue__button button button--primary"><span>Continue</span>
                            </a>

                            <a href="/" onClick={() => window.close()} className="button button--secondary"><span>Cancel</span></a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div>
            Please, login to authorize
        </div>
    );
}

const mapStateToProps = function (state) {
    return {
        logged: state.user.logged,
        user: state.user.user,
        apiToken: state.user.apiToken,
    }
};

export default connect(mapStateToProps, {refreshUser})(OAuth2);
