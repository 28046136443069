import React, {Component} from 'react';
import './Inventory.sass';
import {connect} from "react-redux";
import {Navigate, NavLink} from "react-router-dom";
import {loadInventory} from "../../services/user/actions";
import InventoryList from "./components/InventoryList";
import ProfileCloud from "../Profile/ProfileCloud";

class Inventory extends Component {
    componentDidMount() {
        document.title = "Inventory - NeosVR WebApp";

        this.props.loadInventory(this.props.apiToken);
    }

    render() {
        const {logged, inventory} = this.props;

        if (!logged) {
            return <Navigate to='/'/>;
        }

        return (
            <div className="App-container">
                <div className="App-top">
                    <ProfileCloud/>
                    <div className="App-inventory-breadcrumb">
                        <NavLink to={"/inventory"}>Inventory</NavLink>
                    </div>
                </div>

                <InventoryList inventory={inventory} inventoryDirectoryActualPath=""/>
            </div>
        );
    }
}

const mapStateToProps = function (state) {
    return {
        logged: state.user.logged,
        user: state.user.user,
        apiToken: state.user.apiToken,
        inventory: state.user.inventory,
    }
};

export default connect(mapStateToProps, {loadInventory})(Inventory);
