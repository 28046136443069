import React, {Component} from 'react';
import InventoryItem from "../components/InventoryItem";
import InventoryLink from "./InventoryLink";
import InventoryOwnerDirectory from "./InventoryOwnerDirectory";
import {connect} from "react-redux";

function InventoryList(props)
{
    const {inventory, inventoryDirectoryActualPath, runningLoads} = props;

    if (!inventory) {
        return <div className="App-loading"></div>;
    }

    let inventoryDirectories = [];
    let inventoryLinks = [];
    let inventoryObjects = [];

    if (inventory.length && inventory.length > 0) {
        inventoryDirectories = inventory.filter(item => item.recordType === "directory");
        inventoryLinks = inventory.filter(item => item.recordType === "link");
        inventoryObjects = inventory.filter(item => item.recordType === "object");
    }


    if (inventoryDirectories.length == 0 && inventoryLinks.length == 0 && inventoryObjects.length == 0 && runningLoads > 0) {
        return <div className="App-loading"></div>;
    }

    return (
        <div className="InventoryList">
            {inventoryDirectories.length > 0 && [
                <h2 key={1} className="App-inventory-title">Folders <strong>{inventoryDirectories.length}</strong></h2>,
                <div key={2} className="App-inventory-row-folder">
                    {inventoryDirectories.map((inventoryItem, index) => (
                        <div key={index} >
                            <InventoryOwnerDirectory data={inventoryItem} inventoryDirectoryActualPath={inventoryDirectoryActualPath} />
                        </div>
                    ))}
                </div>
            ]}

            {inventoryLinks.length > 0 && [
                <h2 key={1} className="App-inventory-title">Links <strong>{inventoryLinks.length}</strong></h2>,
                <div key={2} className="App-inventory-row-folder">
                    {inventoryLinks.map((inventoryItem, index) => (
                        <div key={index}>
                            <InventoryLink data={inventoryItem} />
                        </div>
                    ))}
                </div>
            ]}

            {inventoryObjects.length > 0 && [
                <h2 key={1} className="App-inventory-title">Items <strong>{inventoryObjects.length}</strong></h2>,
                <div key={2} className="App-inventory-row">
                    {inventoryObjects.map((inventoryItem, index) => (
                        <div key={index} >
                            <InventoryItem data={inventoryItem} />
                        </div>
                    ))}
                </div>
            ]}
        </div>
    );
}

const mapStateToProps = function (state) {
    return {
        runningLoads: state.loader.runningLoads
    }
};

export default connect(mapStateToProps, null)(InventoryList);
