import ReactModal from "react-modal";
import React from "react";
import {connect} from "react-redux";

function SendTransactionModal(props) {
    const {isOpen, close, user} = props;

    return (
        <ReactModal
            isOpen={isOpen}
            onRequestClose={close}
            ariaHideApp={false}
            style={{content: {background: "none", border: "none"}, overlay: {background: "rgba(0,0,0,.6)"}}}
        >
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h2 className="App-title">Deposit</h2>
                    </div>
                    <div className="modal-body">
                        <p>
                            Deposit address for NCR: <strong>{user.credits.address_ncr}</strong>
                        </p>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="button button--secondary" data-dismiss="modal"
                                onClick={close}>
                            <span>Close</span>
                        </button>
                    </div>
                </div>
            </div>
        </ReactModal>
    );
}

const mapStateToProps = function (state) {
    return {
        user: state.user.user
    }
};

export default connect(mapStateToProps, null)(SendTransactionModal);