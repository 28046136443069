import ReactModal from "react-modal";
import React from "react";
import {connect} from "react-redux";
import AddStorageForm from "../../forms/storage/AddStorageForm";

function MiningSelectClientModal(props) {
    const {isOpen, close} = props;

    return (
        <ReactModal
            isOpen={isOpen}
            onRequestClose={close}
            ariaHideApp={false}
            className={"modal-608"}
            style={{content: {background: "none", border: "none"}, overlay: {background: "rgba(0,0,0,.6)"}}}
        >
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-body">
                        <h2 className="App-title text-center mb-44">Select client</h2>

                        <div className="w-400">
                        <div className="row  text-center">
                            <div className="col-6">
                                <a className="button button--primary button--client button--client--unity" href="http://cloudx.azurewebsites.net/open/world/U-Murphy-Cooper/R-4082f661-4e97-459e-b764-b00d6ba40acc" target="_blank">
                                    <span>NeosVR</span>
                                </a><br/>
                                <span className="button__desc">
                                Get NeosVR on <strong>Steam</strong>
                                </span>
                            </div>
                            <div className="col-6">
                                <a className="button button--primary button--client button--client--unreal" href="https://assets.neos.com/coopers/Neos_Cooper's_Planet_Installer.exe" target="_blank">
                                    <span>Neos Metaverse</span>
                                </a><br/>
                                <span className="button__desc">
                                Download Neos Metaverse
                                </span>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button className="button-close" data-dismiss="modal"
                                onClick={close}>X</button>
                    </div>
                </div>
            </div>
        </ReactModal>
    );
}


export default connect(null, null)(MiningSelectClientModal);