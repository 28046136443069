import React, {Component} from 'react';
import {NavLink} from "react-router-dom";

class InventoryLink extends Component {

    render() {
        const {data} = this.props;

        const tmp = data.assetUri.split("/");
        const id = tmp[tmp.length-1];
        const owner = tmp[tmp.length - 2];

        return (
            <NavLink to={"/inventory/link/" + owner + "/" + id} className="App-inventory-box App-inventory-box--mini-ico">
                <div>
                    <img src="/ui/assets/images/icon/folder.svg" width="28" height="24" alt="Folder"/>
                </div>
                <h3 className="App-inventory-box-link">
                        {data.name}
                </h3>
            </NavLink>
        );
    }
}

export default InventoryLink;