import React, {Component} from 'react';
import './Loader.sass';
import {connect} from "react-redux";

class Loader extends Component {
    render() {
        if (this.props.runningLoads <= 0) {
            return "";
        }

        return (
            <div className="Loader">
                Loading...
            </div>
        );
    }
}

const mapStateToProps = function (state) {
    return {
        runningLoads: state.loader.runningLoads
    }
};

export default connect(mapStateToProps, null)(Loader);
