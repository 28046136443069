export const USER = {
    RECIVE: "USER.RECIVE",
    RECIVE_STORAGE_PLAN: "USER.RECIVE_STORAGE_PLAN",
    RECIVE_SESSION: "USER.RECIVE_SESSION",
    RECIVE_API_TOKEN: "USER.API_TOKEN",
    RECIVE_2FA: "USER.RECIVE_2FA",
    REGISTRATION: "USER.REGISTRATION",
    REFRESH: "USER.REFRESH",
    LOGIN: "USER.LOGIN",
    LOGOUT: "USER.LOGOUT",
    RECIVE_INVENTORY: "USER.RECIVE_INVENTORY",
    RECIVE_INVENTORY_DIRECTORY: "USER.RECIVE_INVENTORY_DIRECTORY",
    RESET_INVENTORY_DIRECTORY: "USER.RESET_INVENTORY_DIRECTORY",
    RECIVE_FRIENDS: "USER.RECIVE_FRIENDS",
    CHOOSE_FRIEND: "USER.CHOOSE_FRIEND",
    RECIVE_MESSAGES: "USER.RECIVE_MESSAGES",
    SEND_START_MESSAGE: "USER.SEND_START_MESSAGE",
    SEND_END_MESSAGE: "USER.SEND_END_MESSAGE",
    FLASH_ERROR: "USER.FLASH_ERROR",
};
