const USE_CDN = false;

const CLOUDX_NEOS_BLOB = "https://cloudxstorage.blob.core.windows.net/";
const CLOUDX_NEOS_CDN = "https://cloudx.azureedge.net/"; // CDN

const NEOS_CLOUD_BLOB = USE_CDN ? CLOUDX_NEOS_CDN : CLOUDX_NEOS_BLOB;

const neos = {
    neosDbToHttp: (neosdb) => {
        if (neosdb === "") {
            return "";
        }

        const sig = neos.neosDBSignature(neosdb);
        return NEOS_CLOUD_BLOB + "assets" + sig;
    },

    neosDBSignature: (neosdb) => {
        if (neosdb === "") {
            return "";
        }

        return neosdb.split('//')[1].split('.')[0];
    },


    getAvatar: (user) => {
        if ("profile" in user && user.profile != null && "iconUrl" in user.profile && user.profile.iconUrl !== "") {
            return neos.neosDbToHttp(user.profile.iconUrl);
        }

        return "/ui/assets/images/profile.png";
    },
};

export default neos