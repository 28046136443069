import React, {Component} from 'react';
import neos from "../../../services/neos";
import {connect} from "react-redux";
import {setChoosedFriend} from "../../../services/user/actions";

class MessageItem extends Component {
    render() {
        const {friend, message, me} = this.props;

        return (
            <div className={"Messages-MessageItem " + (message.senderId === me.id ? "Messages-MessageItem--me" : "")}>
                <div className="Messages-MessageItem__content">
                    {message.messageType === "Text" ? (
                        <p>{message.content}</p>
                    ):(
                        <p><em>Not supported type of message. ({message.messageType})</em></p>
                    )}
                </div>
                <div className="Messages-MessageItem__time">{message.sendTime}</div>
            </div>
        );
    }
}

export default MessageItem;