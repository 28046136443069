import React, {useEffect} from 'react';
import {Link, Route, Routes, NavLink, BrowserRouter, useLocation} from "react-router-dom";
import Home from './pages/Home/Home';
import NotFound from './pages/NotFound/NotFound';
import User from './pages/User/User';
import ReactModal from 'react-modal';
import './sass/index.sass';
import API from "./api";
import {connect} from "react-redux";
import Loader from "./pages/Loader/Loader";
import OAuth2 from "./pages/OAuth2/OAuth2";
import {refreshUser, logout} from './services/user/actions';
import Profile from "./pages/Profile/Profile";
import Messages from "./pages/Messages/Messages";
import Inventory from "./pages/Inventory/Inventory";
import InventoryLinkList from "./pages/Inventory/layouts/InventoryLinkList";
import InventoryOwnerDirectoryList from "./pages/Inventory/layouts/InventoryOwnerDirectoryList";
import Login from "./pages/Login/Login";
import Time from "./pages/Time/Time";
import { Scrollbars } from 'react-custom-scrollbars';
import Register from "./pages/Register/Register";
import LostPassword from "./pages/LostPassword/LostPassword";
import ResetPassword from "./pages/ResetPassword/ResetPassword";

function App(props) {
    let {logged, userSession, user} = props;

    useEffect(()=>{
        if (user) {
            API.jwt = user.jwt;
        }

        if (userSession) {
            if (userSession.expire) {
                let expire = new Date(userSession.expire);
                let now = new Date();
                if (now >= expire) {
                    props.logout(userSession);
                } else {
                    console.log("s")
                    props.refreshUser(userSession);
                }
            }
        }
    }, []);


    let pathname = window.location.pathname;
    console.log(pathname)

    const min = 1;
    const max = 8;
    const rand = Math.round(min + Math.random() * (max - min));
    let stylePath = "/ui/bg/style_" + rand + ".css";

    return (
        <BrowserRouter basename='/ui'>
            {!logged && (
                <video autoPlay muted loop id="background-video">
                    <source src="/ui/assets/background-loop.mp4" type="video/mp4"/>
                </video>
            )}
            <div className="App">
                <link rel="stylesheet" type="text/css" href={stylePath}/>
                <div className="App-inner">

                    <main>
                        <Scrollbars

                            renderThumbVertical={({style, ...props}) => {
                                const thumbStyle = {
                                    backgroundColor: `rgba(200, 200, 255, .2)`,
                                    borderRadius: "3px"
                                };
                                return (
                                    <div
                                        style={{...style, ...thumbStyle}}
                                        {...props}/>
                                );
                            }}
                            className="main__inner" style={{width: "100%", height: "100%"}}>
                            <div className="container">
                                {logged ? (
                                    <Routes>
                                        <Route exact="true" path="/" element={<Home/>}/>
                                        <Route exact="true" path="/profile" element={<Profile/>}/>
                                        <Route exact="true" path="/inventory/directory/:owner/:directoryId"
                                               element={<InventoryOwnerDirectoryList/>}/>
                                        <Route exact="true" path="/inventory/link/:owner/:linkId"
                                               element={<InventoryLinkList/>}/>
                                        <Route exact="true" path="/inventory" element={<Inventory/>}/>
                                        <Route exact="true" path="/messages" element={<Messages/>}/>
                                        <Route exact="true" path="/oauth2/*" element={<OAuth2/>}/>
                                        <Route path="*" element={<NotFound/>} status={404}/>
                                    </Routes>
                                ) : (
                                    <Routes>
                                        <Route exact="true" path="/lostpassword" element={<LostPassword/>}/>
                                        <Route exact="true" path="/resetpassword" element={<ResetPassword/>}/>
                                        <Route exact="true" path="/register" element={<Register/>}/>
                                        <Route exact="true" path="/" element={<Login/>}/>
                                        <Route path="*" element={<Login/>} status={404}/>
                                    </Routes>
                                )}
                            </div>
                        </Scrollbars>
                    </main>

                    {pathname !== "/ui/oauth2/authorize" && (
                        <div className="App-container-header">
                            <header className="App-header">
                                <div className="App-header-time">
                                    <Time/>
                                    {/*<span className="App-header-time-online">Online: <span>1</span></span>*/}
                                </div>
                                <div className="App-header-logout">
                                    <button onClick={() => {
                                        props.logout(userSession);
                                        window.location.href = "/";
                                    }}>X
                                    </button>
                                </div>
                                <nav className="App-header-nav">
                                    <ul>
                                        <li><NavLink exact="true"
                                                     className={({isActive}) =>
                                                         isActive ? "is-active" : ""
                                                     } to="/">
                                    <span>
                                        <img src="/ui/assets/images/icon/house.svg" width="24" height="24" alt=""/>
                                    </span><br/>
                                            <span>Home</span>
                                        </NavLink></li>
                                        {logged && (
                                            <>
                                                <li><NavLink
                                                    className={({isActive}) =>
                                                        isActive ? "is-active" : ""
                                                    } to="/inventory">
                                        <span>
                                            <img src="/ui/assets/images/icon/view_comfy.svg" width="24" height="24"
                                                 alt=""/>
                                        </span><br/>
                                                    <span>Inventory</span>
                                                </NavLink></li>
                                                <li>
                                                    <a className="is-disable">
                                                <span>
                                                    <img src="/ui/assets/images/icon/public.svg" width="24" height="24"
                                                         alt=""/>
                                                </span><br/>
                                                        <span>Worlds</span>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a className="is-disable">
                                                <span>
                                                    <img src="/ui/assets/images/icon/people_alt.svg" width="24"
                                                         height="24"
                                                         alt=""/>
                                                </span><br/>
                                                        <span>Contacts</span>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a className="is-disable">
                                                <span>
                                                    <img src="/ui/assets/images/icon/dataflow-01.svg" width="24"
                                                         height="24"
                                                         alt=""/>
                                                </span><br/>
                                                        <span>Sessions</span>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a className="is-disable">
                                                <span>
                                                    <img src="/ui/assets/images/icon/tune.svg" width="24" height="24"
                                                         alt=""/>
                                                </span><br/>
                                                        <span>Settings</span>
                                                    </a>
                                                </li>
                                            </>
                                        )}
                                        {/*logged && [
                                    <li key={1}><NavLink activeclassname='is-active' to="/inventory">Inventory</NavLink></li>,
                                    <li key={2}><NavLink activeclassname='is-active' to="/messages">Messages</NavLink></li>,
                                    <li key={3}><NavLink activeclassname='is-active' to="/profile">Profile</NavLink></li>




                                <li className="pl-sm-4"><User/></li>
                                ]*/}
                                    </ul>
                                </nav>
                            </header>
                        </div>
                    )}

                </div>
            </div>

            <div className={"App-mobile-rotation"}>
                <div className={"App-mobile-rotation__inner"}>
                    <img src="/ui/assets/images/icon/phone-rotation.svg" width="100" height="100"/>
                    <p>For better experience, please rotate your device.</p>
                </div>
            </div>
            {/*
            <div className={"App-mobile-rotation"}>
                <div className={"App-mobile-rotation__inner"}>
                    <img src="/ui/assets/images/icon/maximize.svg" width="75" height="75"/>
                    <p>For better experience, please expand the window.</p>
                </div>
            </div>
            */}
            <Loader/>
        </BrowserRouter>
    );
}


const mapStateToProps = function (state) {
    return {
        logged: state.user.logged,
        user: state.user.user,
        userSession: state.user.userSession,
    }
};

export default connect(mapStateToProps, {
    refreshUser, logout
})(App);