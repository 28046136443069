import ReactModal from "react-modal";
import {React, useState} from "react";
import {connect} from "react-redux";
import {sendTransaction} from "../../services/transaction/actions";
import {changePatreonEmail, refreshUser} from "../../services/user/actions";
import {loadNotifications} from "../../services/notification/actions";

function PatreonEmailForm(props) {
    const {flashError, apiToken,  changePatreonEmail,} = props;

    const [success, setSuccess] = useState("");

    function handleSubmit(e) {
        e.preventDefault();

        // Read the form data
        const form = e.target;
        const formData = new FormData(form);
        const formJson = Object.fromEntries(formData.entries());

        changePatreonEmail(apiToken, formJson, function (){
            setSuccess("Your patreon email was changed.");
        });
    }

    return (
        <form onSubmit={(e)=> handleSubmit(e)} className="form">
            {/*flashError && (
                <p className="text-danger">{flashError}</p>
            )*/}
            {success != "" && (
                <p className="text-success">{success}</p>
            )}

            <div className={"App-single-form"}>
                <input type="email" className={"App-input"} name="patreonEmail" defaultValue={"patreonEmail" in props.user ? props.user.patreonEmail : ""}/>
                <button type="submit" className={"App-button App-button-secondary"}>
                    <span>
                        Confirm
                    </span>
                </button>
            </div>
        </form>
    );
}

const mapStateToProps = function (state) {
    return {
        apiToken: state.user.apiToken,
        flashError: state.user.flashError,
        user: state.user.user,
    }
};
export default connect(mapStateToProps, {changePatreonEmail, refreshUser})(PatreonEmailForm);