import React, { useEffect} from 'react';
import {connect} from "react-redux";
import {Navigate, useParams, NavLink} from "react-router-dom";
import {loadInventoryLink, resetInventoryDirectory} from "../../../services/user/actions";
import InventoryList from "../components/InventoryList";
import ProfileCloud from "../../Profile/ProfileCloud";

function InventoryLinkList(props)
{
    let { linkId, owner } = useParams();
    const {logged, inventoryDirectory, inventoryDirectoryActualPath} = props;

    useEffect(()=>{
        document.title = "Inventory - NeosVR WebApp";

        props.resetInventoryDirectory();
        props.loadInventoryLink(props.apiToken, linkId, owner);

        return () => {
            props.resetInventoryDirectory();
        }
    }, [linkId, owner]);

    if (!logged) {
        return <Navigate to='/'/>;
    }

    return (
        <div className="App-container">
            <div className="App-top">
                <ProfileCloud/>

                <div className="App-inventory-breadcrumb">
                    <NavLink to={"/inventory"}>Inventory</NavLink>
                    {inventoryDirectoryActualPath && (
                        <a>{inventoryDirectoryActualPath}</a>
                    )}
                </div>
            </div>

            <InventoryList inventory={inventoryDirectory} inventoryDirectoryActualPath={inventoryDirectoryActualPath}/>
        </div>
    );
}

const mapStateToProps = function (state) {
    return {
        logged: state.user.logged,
        user: state.user.user,
        apiToken: state.user.apiToken,
        inventoryDirectory: state.user.inventoryDirectory,
        inventoryDirectoryActualPath: state.user.inventoryDirectoryActualPath
    }
};


export default connect(mapStateToProps, {loadInventoryLink, resetInventoryDirectory})(InventoryLinkList);
