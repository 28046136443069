import {applyMiddleware, createStore} from "redux";
import thunk from 'redux-thunk';
import {load, save} from "redux-localstorage-simple"
import rootReducer from "./reducers";

const createStoreWithMiddleware = applyMiddleware(
    save(), // Saving done here
    thunk
)(createStore);

export default createStoreWithMiddleware(rootReducer, load());
