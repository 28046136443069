import {connect} from "react-redux";
import React, {useState} from "react";
import AddStorageModal from "../../modals/storage/AddStorageModal";

function parseValue(value) {
    return Math.round(value / 1024 / 1024 / 1024  * 100) / 100 + " GB"
}

function getPercent(user) {
    return Math.round(100/user.quotaBytes*user.usedBytes);
}

function getPercentForBlock(user, key, count) {
    const percent = getPercent(user);
    if (100/count*(key+1) < percent) {
        return 100;
    }
    if (100/count*key > percent) {
        return 0;
    }
    let left = percent - (100/count*key);
    let right = (100/count*(key+1)) - (100/count*key);

    return Math.round(left/right*100);
}

function ProfileCloud (props) {
    const {user} = props;
    const [addSpace, setAddSpace] = useState(false);

    return (
        <div className="App-profile-cloud">
            <span className="color-gray">Neos Cloud:</span> <span>{getPercent(user)}% {parseValue(user.usedBytes)} of {parseValue(user.quotaBytes)}</span>
            <div className="App-profile-cloud__bar">
                <div className="App-profile-cloud__bar__dots">
                    {[...Array(15).keys()].map(i=> (
                        <span>
                            <span style={{width: getPercentForBlock(user, i, 15)+"%"}}></span>
                        </span>
                    ))}
                </div>
            </div>
            <span className="App-profile-cloud__plus "  onClick={() => setAddSpace(true)}>Add storage space</span>
            {/**/}
            <AddStorageModal isOpen={addSpace} close={() => setAddSpace(false)}/>

        </div>
    );
}

const mapStateToProps = function (state) {
    return {
        user: state.user.user,
    }
};
export default connect(mapStateToProps, null)(ProfileCloud);