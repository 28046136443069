import ReactModal from "react-modal";
import React from "react";
import {connect} from "react-redux";
import AddStorageForm from "../../forms/storage/AddStorageForm";

function AddStorageModal(props) {
    const {isOpen, close, user} = props;

    return (
        <ReactModal
            isOpen={isOpen}
            onRequestClose={close}
            ariaHideApp={false}
            className={"modal-752"}
            style={{content: {background: "none", border: "none"}, overlay: {background: "rgba(0,0,0,.6)"}}}
        >
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-body">
                        <AddStorageForm onSubmit={close}/>
                    </div>
                    <div className="modal-footer">
                        <button className="button-close" data-dismiss="modal"
                                onClick={close}>X</button>
                    </div>
                </div>
            </div>
        </ReactModal>
    );
}

const mapStateToProps = function (state) {
    return {
        user: state.user.user
    }
};

export default connect(mapStateToProps, null)(AddStorageModal);