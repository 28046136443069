import ReactModal from "react-modal";
import {React, useState} from "react";
import {connect} from "react-redux";
import {loadStoragePlan, refreshUser, saveStoragePlan, cancelStoragePlan} from "../../services/user/actions";
import {loadNotifications} from "../../services/notification/actions";
import PatreonEmailForm from "./PatreonEmailForm";
import {NavLink} from "react-router-dom";

function AddStorageForm(props) {
    const {
        flashError,
        storagePlane,
        saveStoragePlan,
        apiToken,
        refreshUser,
        loadNotifications,
        loadStoragePlan,
        cancelStoragePlan,
        is2FA
    } = props;

    const [ncr, setNcr] = useState("");
    const [sended, setSended] = useState(false);

    function handleSubmit(e) {
        e.preventDefault();

        // Read the form data
        const form = e.target;
        const formData = new FormData(form);
        const formJson = Object.fromEntries(formData.entries());

        setNcr(formJson.ncr);

        saveStoragePlan(apiToken, formJson);
    }

    function handleCancel(e) {
        e.preventDefault();

        if (window.confirm("Are you sure?")) {
            cancelStoragePlan(apiToken);
        }


    }

    function handle2FA(e) {
        e.preventDefault();

        // Read the form data
        const form = new FormData(e.target);
        const formJson = {ncr};
        formJson.code2FA = form.get("code2FA")

        saveStoragePlan(apiToken, formJson, function () {
            refreshUser(apiToken);
            loadNotifications(apiToken);
            loadStoragePlan(apiToken);
            setSended(true);
        });
    }

    return (
        <div>

            {flashError && flashError == 'Oops! Not enough funds' ? (
                <div>
                    <h2 className="App-title text-center mb-35">
                        Oops! Not enough funds
                    </h2>

                    <p className={"text-center"}>
                        Please insert more coins into your virtual piggy bank and try again.
                    </p>

                    <br/>
                    <p className={"text-center"}>
                        <NavLink to="#deposit" className="App-button p-40">
                            <span>
                                Add NCR
                            </span>
                        </NavLink>
                    </p>

                    <br/>

                    <p className={"text-center small "} style={{marginBottom:"-2.5rem"}}>
                        Having issues with your account?<br/>
                        Contact us at hel'lo@neos.com and our team will assist you with any concerns.
                    </p>
                </div>
            ) : (
                sended ? (
                        <div>
                            <h2 className="App-title text-center mb-35">
                                Thank you, your Neos Cloud storage will be increased shortly.
                            </h2>

                            <p className={"text-center"}>
                                You can now sit back and contemplate the mysteries of the universe while our servers do the
                                heavy lifting.
                            </p>
                        </div>
                    ) : (
                (
                    <div>
                        {is2FA ? (
                            <div>
                                <form onSubmit={(e) => is2FA ? handle2FA(e) : handleSubmit(e)} className="form">
                                    <h3>2 Factor Authentification</h3>

                                    <p>The code has been sent to your email.</p>


                                    {flashError && (
                                        <p className="text-danger">{flashError}</p>
                                    )}

                                    <div className="form-group">
                                        <input type="text" name="code2FA" placeholder="Enter code" className="App-input"
                                               required="required"/>
                                    </div>
                                    <button type="submit" className="App-button App-button-secondary">
                                        <span>
                                            Send
                                        </span>
                                    </button>
                                </form>
                            </div>
                        ) : (
                            <div>
                                <h2 className="App-title text-center mb-35">Choose storage plan</h2>

                                {storagePlane && "user_id" in storagePlane && storagePlane.canceled == null && (
                                    <div className={"mb-12"}>
                                        <button className={"App-button App-button-secondary float-right"}
                                                onClick={handleCancel}><span>Cancel subscription</span></button>
                                        Your current plan: {storagePlane.ncr != "0" ? (
                                        <span>{storagePlane.ncr} NCR monthly</span>) : "-"} <br/>
                                        - Status: {storagePlane.message}<br/><br/>
                                    </div>
                                )}

                                {flashError && (
                                    <p className="text-danger">{flashError}</p>
                                )}

                                <div className="row text-center mb-12">
                                    <div className="col-4">
                                        <form onSubmit={(e) => is2FA ? handle2FA(e) : handleSubmit(e)} className="form">
                                            <input type="hidden" name="ncr" value="5"/>
                                            <button type="submit" className="App-button App-button--tw">
                                                <span>
                                                    <strong>10GB</strong><br/>
                                                    5 NCR monthly
                                                </span>
                                            </button>
                                        </form>
                                    </div>
                                    <div className="col-4">
                                        <form onSubmit={(e) => is2FA ? handle2FA(e) : handleSubmit(e)} className="form">
                                            <input type="hidden" name="ncr" value="20"/>
                                            <button type="submit" className="App-button App-button--tw">
                                                <span>
                                                    <strong>50GB</strong><br/>
                                                    20 NCR monthly
                                                </span>
                                            </button>
                                        </form>
                                    </div>
                                    <div className="col-4">
                                        <form onSubmit={(e) => is2FA ? handle2FA(e) : handleSubmit(e)} className="form">
                                            <input type="hidden" name="ncr" value="50"/>
                                            <button type="submit" className="App-button App-button--tw">
                                                <span>
                                                    <strong>150GB</strong><br/>
                                                    50 NCR monthly
                                                </span>
                                            </button>
                                        </form>
                                    </div>
                                </div>

                                <br/>

                                <p>
                                    Already purchased storage on Patreon?<br/>
                                    Please enter your Patreon email to link your accounts.
                                </p>
                                <PatreonEmailForm onSubmit={props.close}/>
                            </div>
                        )}

                    </div>
                )
                    )
            )}
        </div>
    );
}

const mapStateToProps = function (state) {
    return {
        apiToken: state.user.apiToken,
        flashError: state.user.flashError,
        is2FA: state.user.is2FA,
        storagePlane: state.user.storagePlane,
    }
};
export default connect(mapStateToProps, {
    saveStoragePlan,
    refreshUser,
    loadNotifications,
    loadStoragePlan,
    cancelStoragePlan
})(AddStorageForm);