import {LOADER} from "./actionTypes";

export const loaderIncrease = () => ({
    type: LOADER.INCREASE
});

export const loaderDecrease = () => ({
    type: LOADER.DEGREASE
});

export const loaderReset = () => ({
    type: LOADER.RESET
});