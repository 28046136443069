import React, {Component, useEffect} from 'react';
import {connect} from "react-redux";
import {Navigate, useParams, NavLink} from "react-router-dom";
import {loadInventoryOwnerDirectory, resetInventoryDirectory} from "../../../services/user/actions";
import InventoryList from "../components/InventoryList";
import ProfileCloud from "../../Profile/ProfileCloud";

function getLinkDirecotry(ownerId, name) {
    let names = name.split("\\");
    let resultParent = "";
    if (names.length > 1) {
        let parentNames = [...names];
        parentNames.pop();
        resultParent = getLinkDirecotry(ownerId, parentNames.join("\\"));
    }
    let link = "/inventory/directory/" + ownerId + "/" + encodeURIComponent(encodeURIComponent(name));
    let result = <NavLink to={link}>{names[names.length - 1]}</NavLink>;
    return (
        <>
            {resultParent}
            {result}
        </>
    );
}

function InventoryOwnerDirectoryList(props)
{
    let {owner, directoryId} = useParams();

    const {logged, inventoryDirectory, inventoryDirectoryActualPath} = props;
    directoryId = decodeURIComponent(directoryId);

    useEffect(()=>{
        document.title = "Inventory - NeosVR WebApp";

        props.resetInventoryDirectory();
        props.loadInventoryOwnerDirectory(props.apiToken, directoryId, owner);

        return () => {
            props.resetInventoryDirectory();
        }
    }, [directoryId, owner]);

    if (!logged) {
        return <Navigate to='/'/>;
    }

    return (
        <div className="App-container">
            <div className="App-top">
                <ProfileCloud/>

                <div className="App-inventory-breadcrumb">
                    <NavLink to={"/inventory"}>Inventory</NavLink>
                    {getLinkDirecotry(owner, inventoryDirectoryActualPath)}
                </div>
            </div>

            <InventoryList inventory={inventoryDirectory} inventoryDirectoryActualPath={inventoryDirectoryActualPath}/>
        </div>
    );
}

const mapStateToProps = function (state) {
    return {
        logged: state.user.logged,
        user: state.user.user,
        apiToken: state.user.apiToken,
        inventoryDirectory: state.user.inventoryDirectory,
        inventoryDirectoryActualPath: state.user.inventoryDirectoryActualPath
    }
};

export default connect(mapStateToProps, {loadInventoryOwnerDirectory, resetInventoryDirectory})(InventoryOwnerDirectoryList);
