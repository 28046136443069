import React, {Component} from 'react';
import {connect} from 'react-redux';
import {registration, registrationLog, login, logout, setFlashError, resetPassword} from '../../services/user/actions';
import secretID from "../../services/secretID";
import {NavLink} from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";

class ResetPassword extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showLoginModal: false,
            showRegistrationModal: false,
            showAfterRegistrationModal: false,
            isRobot: true,
            credentials: {},

            toHome: false
        };
        document.title = "Neos Account - Reset Password";


        this.handleRegistration = this.handleRegistration.bind(this);
        this.onChangeRecaptcha = this.onChangeRecaptcha.bind(this);
        this.onErrorRecaptcha = this.onErrorRecaptcha.bind(this);

    }

    onChangeRecaptcha(value) {
        this.setState({isRobot: false});
    }

    onErrorRecaptcha(value) {
        this.setState({isRobot: true});
    }

    handleRegistration(event) {
        event.preventDefault();
        this.props.setFlashError(null);
        let _self = this;
        const user = new FormData(event.target);

        if (this.state.isRobot) {
            this.props.setFlashError("Are you really not a robot?");
            return;
        }

        if (!secretID.get()) {
            secretID.generate();
        }

        const data = {
            RecoverCode: user.get("recoverycode"),
            Email: user.get("email"),
            Password: user.get("password"),
        };

        this.props.resetPassword(data).then(function () {
            if (!_self.props.flashError) {
                window.location.href="/";
            }
        });
    }

    render() {
        const {flashError} = this.props;

        return (
            <div className="App-vertical-center">
                <div className="App-login App-box App-box-light">

                    <form onSubmit={this.handleRegistration} className="form">
                        <h1 className="App-title">Reset Password</h1>

                        {flashError && (
                            <p className="text-danger">{flashError}</p>
                        )}

                        <div className="form-group">
                            <input type="text" name="recoverycode" placeholder="Recover Code" className="App-input"
                                   required="required"/>
                        </div>
                        <div className="form-group">
                            <input type="text" name="email" placeholder="E-mail" className="App-input"
                                   required="required"/>
                        </div>
                        <div className="form-group">
                            <input type="password" name="password" placeholder="Password" className="App-input"
                                   required="required"/>
                        </div>

                        <div className="form-group">
                            <ReCAPTCHA
                                sitekey="6LfIwLoZAAAAAA2cBRLcFWuwd1PbfRuklUFLwZTK"
                                onExpired={this.onErrorRecaptcha}
                                onErrored={this.onErrorRecaptcha}
                                onChange={this.onChangeRecaptcha}
                            />
                        </div>
                        <button type="submit" className="App-button w-100"><span>Reset passwrod</span></button>

                    </form>
                </div>
            </div>
        );
    }
}

const mapStateToProps = function (state) {
    return {
        logged: state.user.logged,
        user: state.user.user,
        is2FA: state.user.is2FA,
        credentials: state.user.credentials,
        flashError: state.user.flashError
    }
};

export default connect(mapStateToProps, {
    registration, login, logout, setFlashError, registrationLog, resetPassword
})(ResetPassword);